.MandatoLineaMilestonesPagamenti-container {
  table.MandatoLineaMilestonesPagamenti {
    > thead > tr > th:first-child {
      width: 0 !important;
      min-width: 0 !important;
      max-width: 0 !important;
      padding: 0 !important;
      > * {
        display: none !important;
      }
    }

    > tbody > tr {
      > td {
        padding-top: 12px !important;
      }
      > td:first-child {
        padding-top: 1px !important;
        position: absolute;
        width: 100%;
        font-weight: bold;
        padding-left: 10px;
        border-bottom: 0;
        // background-color: #93939311;
      }
    }

    > tbody > tr:hover > td:first-child {
      background-color: transparent;
    }
  }
}
