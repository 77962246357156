.ContrattiCell {
  white-space: nowrap;
  position: relative;

  &.ContrattiCell-editable {
    .ContrattiCell-n,
    .ContrattiCell-i {
      margin-left: 20px;
    }

    .ContrattiCell-edit-button-container {
      visibility: hidden;
      position: absolute;
      left: 0;
      margin-left: -4px;
      .MuiSvgIcon-root {
        width: 16px;
        height: 16px;
      }
    }
  }

  .ContrattiCell-n {
    font-size: 0.8em;
    line-height: 0.9;
  }
  .ContrattiCell-i {
    font-weight: bold;
    line-height: 1.1;
    padding-top: 3px;
  }
}

td:hover .ContrattiCell.ContrattiCell-editable {
  .ContrattiCell-edit-button-container {
    visibility: visible;
  }
}
