.AttivitaKanbanCard {
  position: relative;
  user-select: none;
  padding-left: 20px !important;

  &.AttivitaKanbanCard-saving {
    user-select: none;
    .AttivitaKanbanCard-saving-bg {
      background-color: #f1f1f16b;
      color: #bdbdbd;
      position: absolute;
      left: 0px;
      right: 0;
      top: 0;
      bottom: 0px;
      display: flex;
      > div {
        margin: auto;
      }
    }
  }

  .AttivitaKanbanCard-statusColor {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 15px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    > div {
      text-transform: uppercase;

      transform-origin: 0 0;
      transform: rotate(270deg);

      position: absolute;
      bottom: 0;
      left: 0;
      margin-left: 1px;
      font-size: 10px;
      font-weight: 600;
      margin-bottom: -7px;
    }
  }

  .AttivitaKanbanCard-title {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    font-weight: bold;
    white-space: break-spaces;

    .AttivitaKanbanCard-title-smaller {
      font-size: 0.9em;
      line-height: 1.1em;
    }
    .AttivitaKanbanCard-title-bigger {
      padding-top: 2px;
      font-size: 1.2em;
      line-height: 1.1em;
    }
    // .AttivitaKanbanCard-title-details {
    //   font-weight: normal;
    //   padding-top: 8px;
    //   font-size: 1em;
    //   line-height: 1.1em;
    // }
  }

  .AttivitaKanbanCard-desc-row {
    display: flex;
    flex-direction: row;

    .AttivitaKanbanCard-desc-row-title,
    .AttivitaKanbanCard-desc-row-value {
      line-height: 1.4em;
    }
    .AttivitaKanbanCard-desc-row-title {
      font-size: 0.8em;
      margin-right: auto;
    }
    .AttivitaKanbanCard-desc-row-value {
      font-weight: bold;
      font-size: 0.9em;
    }
  }
}

.AttivitaKanbanCard-blinking {
  animation-duration: 2s;
  animation-name: blinkingAnimation;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes blinkingAnimation {
  from {
    color: #ddd;
    background-color: #ff0066;
  }
  to {
    color: #333;
    background-color: #FF00FF;
  }
}
