.editor-with-toolbar {
  // border: 1px solid #ddd;
  // padding: 7px 5px;
  // margin-bottom: 12px;

  .editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 10px;
    border-radius: 2px;
    margin-bottom: 2em;
    background: #fefefe;
  }

  .editor :global(.public-DraftEditor-content) {
    min-height: 140px;
    max-height: 200px;
    overflow-y: auto;
  }

  .DraftEditor-root {
    margin-top: -8px;
  }

  .headlineButtonWrapper {
    display: inline-block;
  }

  .headlineButton {
    background: #fbfbfb;
    color: #888;
    font-size: 18px;
    border: 0;
    padding-top: 5px;
    vertical-align: bottom;
    height: 34px;
    width: 36px;
  }

  .headlineButton:hover,
  .headlineButton:focus {
    background: #f3f3f3;
  }
}
