.CaricoDiLavoro {
  position: relative;

  .CaricoUserSection_table,
  .CaricoScadenzaSection_table {
    td,
    th {
      border-right: 1px solid rgba(224, 224, 224, 1);
    }
    th {
      border-top: 1px solid rgba(224, 224, 224, 1);
    }
  }

  .CaricoDiLavoro-kanban-container-Left {
    overflow: hidden;
    position: absolute;
    margin-left: -8px;
    background-color: #fafafa;
    z-index: 1;
  }
}

.CaricoDiLavoro-kanban-container-spacer,
.CaricoDiLavoro-kanban-drawer,
.CaricoDiLavoro-kanban-drawer-container,
.CaricoDiLavoro-kanban-container {
  height: 60vh;
  // background-color: green;
}

.CaricoDiLavoro-kanban-container-main {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.CaricoDiLavoro-kanban-drawer-container {
  overflow: hidden;
  .creaconsulting-kanban {
    .react-trello-board {
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.CaricoDiLavoro-kanban-drawer-collapsed,
.CaricoDiLavoro-kanban-drawer-container-collapsed {
  max-height: 180px;
}

.CaricoDiLavoro-kanban-drawer {
  pointer-events: none;
  left: 240px !important;
  bottom: 0 !important;
  height: calc(60vh + 30px);
  top: unset !important;
  overflow: hidden;

  > .MuiPaper-root {
    position: relative;
    margin-top: 30px;
    // left: 240px;
  }
  .CaricoDiLavoro-kanban-drawer-container {
    pointer-events: all;
  }
}

.CaricoDiLavoro-kanban-drawer,
.CaricoDiLavoro-kanban-container {
  width: calc(100vw - 240px);
}

.CaricoDiLavoro-kanban-container {
  display: flex;
  flex-direction: column;
}

.CaricoDiLavoro-kanban-container {
  position: fixed;
  bottom: 0;
  left: 240px;

  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.CaricoDiLavoro-kanban-drawer {
  .creaconsulting-kanban {
    height: unset !important;
    .react-kanban-board {
      .react-kanban-column {
        max-height: calc(60vh - 100px);
      }
    }
    .react-trello-board {
      .react-trello-lane {
        max-height: calc(60vh - 100px);
      }
    }
  }
}

.CaricoDiLavoro-kanban-drawer {
  .CaricoDiLavoro-kanban-filter {
    flex: 1000 1;
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: 10px;
    align-items: baseline;

    > .filter-pre {
      font-size: 0.9rem;
      font-weight: bold;
    }
    > .filter {
      font-size: 1.1rem;
    }
  }
}
