$pivot-table-border: 1px solid rgba(224, 224, 224, 1);
$pivot-table-background: #fff;

.pivot-table-container {
  display: flex;
  overflow-x: auto;

  table.pivot-table {
    position: relative;
    border-collapse: collapse;
    table-layout: fixed;
    margin-bottom: 12px;

    th,
    td {
      border: $pivot-table-border;
      padding: 8px 4px;
      background-color: $pivot-table-background;
      text-align: center;
      &.pivot-col-1,
      &.pivot-col-2 {
        text-align: left;
        // position: sticky;
        // left: 0;
        // z-index: 1;
        // background-color: white;
      }
    }

    thead {
      th {
        position: sticky;
        background-color: $pivot-table-background;
        top: 0;
      }
    }

    td.expand-td,
    th.expand-td {
      padding: 0;
      border-right: none;
      + td,
      + th {
        border-left: none;
      }
    }

    tr:hover {
      td {
        background-color: darken($pivot-table-background, 2%);
      }
    }

    tr.pivot-row-2 {
      td.pivot-col-1 {
        border: none;
        background-color: unset;
      }
    }
  }
}
