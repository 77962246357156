.filter-clear-and-not-buttons-container {
  display: flex;
  flex-direction: row;
  gap: 1px;
  align-items: center;
}

.table-filter-not-button {
  background-color: transparent;
  border: 1px solid gray;
  font-size: 0.5em;
  border-radius: 3px;
  cursor: pointer;
  display: none;
  padding: 2px 4px;
}

.table-filter-not-button.filter-enabled {
  color: white;
  font-weight: bold;
  background-color: #0084a9;
  border-color: #0084a9;
  display: block;
}

.MuiOutlinedInput-root:hover .table-filter-not-button {
  display: block;
}

.MuiAutocomplete-root {
  .MuiChip-root + * + .filter-clear-and-not-buttons-container {
    .table-filter-not-button {
      top: calc(50% + 7px);
      right: 10px;
      position: absolute;
    }
    .MuiAutocomplete-endAdornment {
      top: calc(50% - 14px - 13px);
      right: 0px;
      position: absolute;
    }
  }
}
