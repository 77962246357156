.ScadenziarioKanbanCard {
  position: relative;
  user-select: none;
  padding-left: 20px !important;

  &.ScadenziarioKanbanCard-saving {
    user-select: none;
    .ScadenziarioKanbanCard-saving-bg {
      background-color: #f1f1f16b;
      color: #bdbdbd;
      position: absolute;
      left: 0px;
      right: 0;
      top: 0;
      bottom: 0px;
      display: flex;
      > div {
        margin: auto;
      }
    }
  }

  .ScadenziarioKanbanCard-statusColor {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 15px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    > div {
      text-transform: uppercase;

      transform-origin: 0 0;
      transform: rotate(270deg);

      position: absolute;
      bottom: 0;
      left: 0;
      margin-left: 1px;
      font-size: 10px;
      font-weight: 600;
      margin-bottom: -7px;
      color: #00000085;
    }
  }

  .ScadenziarioKanbanCard-separator {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  .ScadenziarioKanbanCard-title {
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    // font-weight: bold;
    // white-space: break-spaces;

    .RichTextEditor-for-event {
      margin: 0 !important;
      .editor {
        cursor: pointer;

        margin-bottom: 0 !important;

        background: transparent;
        border: 0px;
        min-height: 0;
        padding: 0px;
        padding-top: 4px;

        font-size: 0.9em;
        line-height: 1;
      }
    }

    .ScadenziarioKanbanCard-title-smaller {
      // font-weight: bold;
      // white-space: break-spaces;
      font-size: 0.9em;
      line-height: 1.1em;
    }
    .ScadenziarioKanbanCard-title-bigger {
      // font-weight: bold;
      // white-space: break-spaces;
      padding-top: 2px;
      font-size: 1.2em;
      line-height: 1.1em;
    }
    // .ScadenziarioKanbanCard-title-details {
    //   font-weight: normal;
    //   padding-top: 8px;
    //   font-size: 1em;
    //   line-height: 1.1em;
    // }
  }

  .ScadenziarioKanbanCard-desc-row {
    display: flex;
    flex-direction: row;

    .ScadenziarioKanbanCard-desc-row-title,
    .ScadenziarioKanbanCard-desc-row-value {
      line-height: 1.4em;
    }
    .ScadenziarioKanbanCard-desc-row-title {
      font-size: 0.8em;
      margin-right: auto;
    }
    .ScadenziarioKanbanCard-desc-row-value {
      font-weight: bold;
      font-size: 0.9em;
    }
  }
}
