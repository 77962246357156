.creaconsulting-kanban {
  .react-kanban-board {
    overflow-x: auto;
    overflow-y: hidden;

    .react-kanban-column {
      max-height: calc(100vh - 180px);
      display: inline-flex !important;
      flex-direction: column;

      & > div:nth-child(2) {
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
  .react-trello-board {
    background-color: transparent;
    // height: calc(100vh - 160px);
    height: unset;
    .react-trello-lane {
      max-height: calc(100vh - 180px);
      width: 280px;
    }
  }
}
