.ScadenziarioCalendar {
  .rbc-agenda-table {
    .rbc-header:nth-child(2) {
      display: none;
    }
    .rbc-agenda-time-cell {
      display: none;
    }

    .rbc-agenda-date-cell {
      background: white;
    }
  }

  .RichTextEditor-for-event {
    .editor {
      cursor: pointer;
    }
  }

  .rbc-month-view {
    .rbc-event {
      height: 100%;
      .rbc-event-content {
        height: 100%;
      }
    }
    .EventTitle {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-top: 2px;

      .RichTextEditor-for-event {
        flex: 1;

        margin: 0 !important;
        .editor {
          margin-bottom: 0 !important;
          overflow-y: hidden;
          // max-height: 24px;

          background: transparent;
          border: 0px;
          min-height: 0;
          padding: 0px;
          padding-top: 7px;

          font-size: 0.9em;
          line-height: 1;

          // ellipsis
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          .public-DraftEditor-content {
            > div {
              // row container
              > * {
                display: inline-block;
              }
              > * + * {
                margin-left: 3px;
              }
              > ul,
              > ol {
                margin: 0;
                margin-block-start: 0;
                margin-block-end: 0;
                padding-inline-start: 0;
                div,
                span {
                  display: inline-block;
                }
                li {
                  display: inline-block;
                  margin-left: 0;
                  padding-left: 2px;
                  padding-right: 2px;
                }
              }
            }

            .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
              position: relative;
              left: 0;
              width: unset;
              text-align: left;
            }
          }
        }
      }
      .EventTitle-bottom {
        flex: 0;
        line-height: 1.2;
        padding-top: 2px;
      }
    }
  }
  .rbc-agenda-table {
    .EventTitle {
      .RichTextEditor-for-event {
        margin: 0 !important;
        .editor {
          margin-bottom: 0 !important;
          overflow-y: scroll;
          max-height: 80px;
          margin-right: -10px;

          background: transparent;
          border: 0px;
          min-height: 0;
          padding: 0px;
          padding-top: 7px;

          font-size: 0.9em;
          line-height: 1;
        }
      }
    }
  }
}
