.budget-subheader {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  min-height: 32px;

  .budget-subheader-button {
    font-size: 0.7em;
  }
}

.budget-subheader:hover {
  .budget-subheader-button {
    display: block !important;
  }
}

.budget-legge-nome-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .delete-budget-legge-button {
    display: none;
  }
}

.budget-legge-nome-cell:hover {
  .delete-budget-legge-button {
    display: block;
  }
}
