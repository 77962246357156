.crea-readOnly {
  .Mui-disabled fieldset,
  .MuiOutlinedInput-root.Mui-disabled fieldset {
    border: none;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.87);
    border-radius: 0;
  }

  .MuiFormControlLabel-root {
    span // CheckBox label
    {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .MuiFormControl-root:not(.portalgen-textFilter) {
    label,
    input,
    .MuiInputBase-root.Mui-disabled // Select
    {
      color: rgba(0, 0, 0, 0.87);
    }

    // Keep label in TextField small and moved on the top left
    .MuiFormLabel-root {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
}

// small table filters
th.MuiTableCell-root.MuiTableCell-head {
  padding-top: 0;
  padding-bottom: 0;
}

.portalgen-numberFilter {
  .portalgen-dualInputContainer span {
    padding: 0 2px;
  }
}

.portalgen-showOnlyEmptyFilter {
  span.MuiIconButton-root {
    padding: 0 2px 0 6px;

    svg {
      width: 0.8em;
    }
  }

  span.MuiFormControlLabel-label {
    font-size: 0.8rem;
  }
}

// outlined style for Table and Accordion
div.MuiTableContainer-root.MuiPaper-elevation1,
div.MuiAccordion-root.MuiPaper-elevation1 {
  box-shadow: none;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}

// reduce bottom padding in cards
div.MuiCardContent-root:last-child {
  padding-bottom: 6px;
}

.data-table-tooltip-container {
  visibility: hidden;
  background-color: #fff;
  padding: 4px;
  -webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.5s ease;
  position: absolute;
  z-index: 100;
}

.MuiTable-root {
  .MuiTableCell-sizeSmall {
    padding: 1px 3px;
  }

  .MuiTableBody-root {
    tr {
      td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      td:hover {
        & > .data-table-tooltip-container {
          visibility: visible;
          transition: opacity 0.5s ease;
          opacity: 1;
        }
      }
    }
  }

  .MuiTableBody-root {
    tr:hover {
      > td {
        background-color: #0084a91f;
      }
    }
  }
}

.crea-lineThrough input,
.crea-lineThrough div.MuiSelect-root {
  text-decoration: line-through;
}

table.MuiTable-root {
  thead {
    tr th {
      font-weight: bold;
    }
  }
}

.input-dati-atoka {
  input {
    background-color: #ff78001c;
  }

  &.data-input-text-field,
  &.MuiTextField-root {
    .MuiInputBase-root {
      background-color: #ff78001c;

      input {
        background-color: transparent;
      }
    }
  }

  .MuiCheckbox-colorSecondary + .MuiFormControlLabel-label {
    background-color: #ff78001c;
  }
}

.UserPicker-smaller {
  margin-top: -6px;

  input {
    zoom: 0.84;
  }
}

.RichTextEditor-in-table {
  margin: 0 !important;

  .editor {
    margin-bottom: 0 !important;
    overflow-y: scroll;
    max-height: 100px;
  }
}

@import "../components/Kanban/Kanban";
@import "../components/Attivita/AttivitaKanbanCard";
@import "../components/CaricoDiLavoro/CaricoDiLavoro";
@import "../components/Calendar/Calendar";
@import "../components/Scadenziario/ScadenziarioCalendar";
@import "../components/Scadenziario/ScadenziarioKanbanCard";

.MuiOutlinedInput-adornedStart {
  padding-left: 0 !important;
}

.MuiAccordion-root {
  margin-bottom: 8px!important;;
}
.MuiAccordion-root.Mui-expanded {
  margin-top: 0!important;;
  margin-bottom: 8px!important;
}

.MuiAccordionSummary-content {
  font-weight: bold;
  margin-left: 12px !important;
}

.MuiAccordion-root::before {
  display: none;
}

//.MuiAccordionSummary-content.Mui-expanded {
//  margin: 12px 0 !important;
//  margin-left: 12px !important;
//}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 25px !important;
}

// PAGE TITLE HEADER AND BUTTONS
$page-title-background: #fafafa;

.page-title-header.page-title-header-sticky {
  position: sticky;
  top: -4px;
  z-index: 900;
  background: $page-title-background;
}

.page-title-header-sticky-buttons-row {
  position: sticky;
  top: 26px;
  z-index: 900;
  background: $page-title-background;
  box-shadow: 0 4px 2px -2px $page-title-background;
}

.MuiDrawer-root {
  .page-title-header {
    display: none;
  }

  .page-title-header-sticky-buttons-row {
    background: white;
    box-shadow: 0 4px 2px -2px white;
    position: sticky;
    top: 0px;
    margin-top: -4px;
    padding-top: 4px;
  }
}

// TABLE FOOTER
.MuiTable-root {
  .MuiTableCell-footer {
    width: 180px;
    color: inherit;
    font-size: unset;
    font-weight: bold;
  }

  .MuiTableFooter-root {
    tr:first-child {
      border-top: 3px solid rgb(224, 224, 224);
    }
  }
}

.with-textfield-black-color {
  .MuiInputBase-root {
    color: rgba(0, 0, 0, 0.87);
  }

  .MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.55);
  }
}
