.DashboardCommerciale {
  .DashboardCommerciale-card {
    .title {
      font-weight: bold;
    }
  }

  .DashboardCommerciale-SimpleNumberCard {
    .title {
      text-align: center;
    }
    .value {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .DashboardCommerciale-TwoNumberCard {
    .title {
      text-align: center;
    }
    .value {
      text-align: center;
    }
    .value2 {
      text-align: center;
      margin-top: 0px;
    }
    .value,
    .value2 {
      margin-bottom: 0;
    }
    .value-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .value-container + .value-container {
      margin-bottom: 10px;
    }
    .extra-container {
      margin-left: 10px;
    }
  }

  .DashboardCommerciale-PercentExtraWithFootnote {
  }

  .footnote-helper {
    p {
      margin: 0;
      font-size: 0.8rem;
    }
  }

  .DashboardCommerciale-analisiCommerciale-table {
    .pivot-table-container {
      justify-content: center;

      .pivot-table {
        min-width: 60%;
      }
    }
  }
}
